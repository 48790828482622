// Default values to be used throughout all SCSS files.
//
// Most of the variables below override Bootstrap's default values. In order to preserve
// that functionality, this file needs to be imported before Bootstrap's variables file.
//
// Variables that haven't been set/defined by Mike are flagged 'Undefined by Mike'
// they are just placeholders.

/* Gray Scale */
$white: #fff !default;
$gray-100: #f5f5f5 !default;
$gray-200: #dddddd !default;
$gray-300: #d1d1d1 !default;
$gray-400: #a6a6a6 !default;
$gray-500: #939393 !default;
$gray-600: #676767 !default;
$gray-700: #454545 !default;
$gray-800: #393939 !default;
$gray-900: #272727 !default;
$black: #000 !default;

/* Brand colors */
$primary: #7754d6 !default;
$accent: #f4f1fa !default;
$secondary: $white !default;
$info: #68bbe3 !default;
$success: #42a542 !default;
$danger: #ff1a1a !default;
$warning: #ffc107 !default;
$light: $gray-100 !default;
$gray-200: $gray-200 !default;
$dark: $gray-900 !default;

/* Modifying Bootstrap's default theme colors */
$theme-colors: (
  'primary': $primary,
  'accent': $accent,
  'secondary': $secondary,
  'info': $info,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'light': $light,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
) !default;

/* Options
 *
 * Modifying Bootstrap global options */
$enable-caret: false !default; // Removes arrow (caret) from .dropdown-toggle
$enable-validation-icons: false; // Removes error iconography

/* Prefix for :root CSS variables 
 *
 * sc = 'S'pec 'C'heck */
$variable-prefix: sc- !default;

/* Body */
$body-bg: $white !default;
$body-color: $gray-900 !default;
$text-muted: $gray-500 !default;

/* Components 
 *
 * Define common padding and border radius sizes and more */
$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.5rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 0.5rem;

$input-border-color: $gray-300 !default;
$input-group-addon-bg: $white;

/* Typography
 *
 * Header */
$headings-color: $black !default;

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.5 !default;

/* Navbar */
$navbar-brand-margin-end: 0 !default;

/* Spacing */
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
) !default;
