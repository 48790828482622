:root {
  --ui-primary-50: #f5f2fa;
  --ui-primary-100: #e3def5;
  --ui-primary-200: #cdc4ee;
  --ui-primary-300: #bfb4e9;
  --ui-primary-400: #a393df;
  --ui-primary-500: #7754d6;
  --ui-primary-600: #5d33cd;
  --ui-primary-700: #3b288b;
  --ui-primary-800: #281a5d;
  --ui-primary-900: #130d2e;

  --ui-neutral-50: #f8f8f9;
  --ui-neutral-100: #ebebed;
  --ui-neutral-200: #d7d6db;
  --ui-neutral-300: #b6b4bd;
  --ui-neutral-400: #95929e;
  --ui-neutral-500: #7b7787;
  --ui-neutral-600: #62606b;
  --ui-neutral-700: #4a4751;
  --ui-neutral-800: #313036;
  --ui-neutral-900: #19181b;

  --ui-info-50: #e8f1fb;
  --ui-info-100: #b9d4f3;
  --ui-info-200: #97bfed;
  --ui-info-300: #68a2e5;
  --ui-info-400: #4a90e0;
  --ui-info-500: #1d74d8;
  --ui-info-600: #1a6ac5;
  --ui-info-700: #155299;
  --ui-info-800: #104077;
  --ui-info-900: #0c315b;

  --ui-success-50: #e8fade;
  --ui-success-100: #c4ebb2;
  --ui-success-200: #aed99c;
  --ui-success-300: #8ec478;
  --ui-success-400: #76b05d;
  --ui-success-500: #5ea34f;
  --ui-success-600: #4a813e;
  --ui-success-700: #487036;
  --ui-success-800: #355428;
  --ui-success-900: #2d4224;

  --ui-warning-50: #fffbeb;
  --ui-warning-100: #fef3c7;
  --ui-warning-200: #fde68a;
  --ui-warning-300: #fcd34d;
  --ui-warning-400: #fbbf24;
  --ui-warning-500: #f59e0b;
  --ui-warning-600: #d97706;
  --ui-warning-700: #b45309;
  --ui-warning-800: #92400e;
  --ui-warning-900: #78350f;

  --ui-error-50: #fef2f2;
  --ui-error-100: #fee2e2;
  --ui-error-200: #fecaca;
  --ui-error-300: #fca5a5;
  --ui-error-400: #f87171;
  --ui-error-500: #ef4444;
  --ui-error-600: #dc2626;
  --ui-error-700: #b91c1c;
  --ui-error-800: #991b1b;
  --ui-error-900: #7f1d1d;

  --ui-icon-sm: 1rem;
  --ui-icon-md: 1.5rem;
  --ui-icon-lg: 2.5rem;

  --ui-space-1: 0.0625rem;
  --ui-space-2: 0.125rem;
  --ui-space-3: 0.25rem;
  --ui-space-4: 0.375rem;
  --ui-space-5: 0.5rem;
  --ui-space-6: 0.75rem;
  --ui-space-7: 1rem;
  --ui-space-8: 1.25rem;
  --ui-space-9: 1.5rem;
  --ui-space-10: 2rem;
  --ui-space-11: 2.5rem;
  --ui-space-12: 5.5rem;

  --motion-productive: cubic-bezier(0.2, 0, 0.38, 0.9);
  --motion-expressive: cubic-bezier(0.4, 0.14, 0.3, 1);

  --motion-productive-enter: cubic-bezier(0, 0, 0.38, 0.9);
  --motion-expressive-enter: cubic-bezier(0, 0, 0.3, 1);

  --motion-productive-exit: cubic-bezier(0.2, 0, 1, 0.9);
  --motion-expressive-exit: cubic-bezier(0.4, 0.14, 1, 1);

  --transition-instant: 70ms;
  --transition-snappy: 110ms;

  --transition-quick: 150ms;
  --transition-moderate: 240ms;

  --transition-relaxed: 400ms;
  --transition-leisurely: 700ms;
}
