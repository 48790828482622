$nav-link-color: $gray-600;
$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: unset;

// Forces tab content to use flexbox to allow for percentage based heights
.tab-content {
  display: flex !important;
  flex-direction: column !important;
  min-height: 0 !important;
  > .active {
    display: flex !important;
    flex-direction: column !important;
    min-height: 0px !important;
  }
}
